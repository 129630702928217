import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Tabs, Tab } from 'react-tabs-scrollable'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import SwipeableViews from 'react-swipeable-views'
import Footer from '../components/footer/footer'
import Wave from '../components/wave/wave'

const Menu = () => {
    const [tab, setTab] = useState(0)
    const data = useStaticQuery(graphql`
        query MenuQuery {
            menu: allAirtable(filter: { table: { eq: "menu" } }) {
                nodes {
                    data {
                        menu_title
                        menu_subtitle
                        price
                        category
                        ID
                    }
                    recordId
                }
            }
        }
    `)
    const {
        menu: { nodes },
    } = data

    const result = nodes
        .sort((a, b) => a.data.ID - b.data.ID)
        .reduce((r, a) => {
            r[a.data.category] = r[a.data.category] || []
            r[a.data.category].push(a)
            return r
        }, Object.create(null))

    const handleChangeIndex = index => {
        const indexedTab = parseInt(index, 10)
        setTab(indexedTab)
    }

    // define a onClick function to bind the value on tab click
    const onTabClick = (e, index) => {
        setTab(index)
    }
    return (
        <>
            <section id="popular" className="module">
                <div className="container p-0">
                    <div className="row">
                        <div
                            className="module-header wow fadeInUp animated"
                            style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                        >
                            <h2 className="module-title">Armenaki Anafi</h2>
                            <h3 className="module-subtitle">Welcome to our menu</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="justify-content-center p-0">
                            <Tabs
                                activeTab={tab}
                                onTabClick={onTabClick}
                                hideNavBtnsOnMobile={false}
                                leftBtnIcon={<FiChevronLeft size="1.5em" />}
                                rightBtnIcon={<FiChevronRight size="1.5em" />}
                            >
                                {Object.keys(result).map((key, index) => (
                                    <Tab key={key}>{key}</Tab>
                                ))}
                            </Tabs>
                            <div className="mb-4" />
                            <SwipeableViews
                                index={tab}
                                onChangeIndex={handleChangeIndex}
                                animateHeight
                            >
                                {Object.keys(result).map((key, index) => (
                                    <div key={index}>
                                        {result[key].map(item => (
                                            <div key={item.recordId} className="menu">
                                                <div className="col-sm-8">
                                                    <h4 className="menu-title">
                                                        {item.data.menu_title}
                                                    </h4>
                                                    <div className="menu-detail">
                                                        {item.data.menu_subtitle}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail">
                                                    <h4 className="menu-price">
                                                        {item.data.price} €
                                                    </h4>
                                                    {item.data.isNew && (
                                                        <div className="menu-label">New</div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </SwipeableViews>
                        </div>
                    </div>

                    <div className="row">
                        {/* <p className="menu-detail">*Αγορανομικός υπεύθυνος: Μάρκος Γαβαλάς</p> */}
                        <p className="menu-detail mt-4">Αγορανομικός υπεύθυνος: Μάρκος Γαβαλάς</p>
                        <div className="col-sm-6 col-sm-offset-3">
                            <div className="devider" />
                        </div>
                    </div>
                </div>
            </section>
            <Wave />
            <Footer />
        </>
    )
}

export default Menu
